<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="isOrganization"
        :is-visible-logout="!isPublic"
        :is-visible-pass-update="!isPublic"
        :is-visible-jigyosya-login="isPublic"
        :is-house="true"
        :is-visible-breadcrumb="true"
        :breadcrumb-category="'ソーラーシステム機器一覧'"
        :breadcrumbItem="packageName"
        :is-visible-user-header="isOrganization"
        :is-visible-administrator-header="isAdministrator"
    >
        <!-- データ取得中は非表示にする -->
        <div v-if="!isInProgress" class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader bl_underHeader__wdfit">
                <h2 class="el_lv2Heading">
                    ソーラーシステム機器
                    <span v-if="isDetail">
                        詳細
                    </span>
                    <span v-if="isDelete">
                        削除
                    </span>
                    <span v-if="isEnabled">
                        承認
                    </span>
                </h2>
                <hr class="hp_flexspacer"/>
                <div
                    v-if="isPublic"
                    key="isPublic"
                >
                    選択されたパッケージ：
                    <span
                        ref="jest-solar-system-detail-package-name"
                    >
                        {{ packageName }}
                    </span>
                    <app-button
                        name="コピー"
                        @click="copy"
                        ref="jest-solar-system-detail-copy-button"
                        class="inline outlined wd_100px uq_btnCopy"
                    />
                </div>
                <div
                    v-if="isDelete"
                    key="isDelete"
                >
                    <p
                        v-if="isSoftDelete"
                        class="red_bold"
                    >
                        削除処理後、以下全てが共通する機器は登録できなくなります。<br>
                        本当に削除しますか？<br>
                        ブランド事業者名：{{brandName}}<br>
                        システム型式（発電・貯湯一体型ユニット含む）：{{solarSystemDetail.system_type}}<br>
                        集熱器型式（貯湯・補助熱源一体型ユニット含む）:{{solarSystemDetail.heat_collector_type}}<br>
                        蓄熱槽型式:{{solarSystemDetail.heat_storage_tank_type}}<br>
                        成績証明書番号または自己適合宣言書番号（試験品質）:{{solarSystemDetail.certificate_number}}<br>
                    </p>
                    <p v-else>以下の機器を削除します。よろしいですか？</p>
                </div>
                <div
                    v-if="isEnabled"
                    key="isEnabled"
                >
                    <p>以下の機器を承認します。よろしいですか？</p>
                </div>
            </div>
            <div class="uq_width_fitter">
                <table>
                    <!-- ラベル部分 -->
                    <tr>
                        <th colspan="3">
                            項目名
                        </th>
                        <th>
                            データ
                        </th>
                    </tr>
                    <!-- 表の中身 -->
                    <device-table-row
                        :name="'ブランド事業者名'"
                        :value="brandName"
                        :name_colspan="3"
                        ref="jest-solar-system-detail-brand-name"
                    />
                    <device-table-row
                        :name="'製造事業者名'"
                        :value="solarSystemDetail.manufacturer_name"
                        :name_colspan="3"
                        ref="jest-solar-system-detail-manufacturer-name"
                    />
                    <device-table-row
                        :name="'システム型式'"
                        :value="solarSystemDetail.system_type"
                        :name_colspan="3"
                        ref="jest-solar-system-detail-system-type"
                    />
                    <device-table-row
                        :name="'集熱器型式'"
                        :value="solarSystemDetail.heat_collector_type"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'蓄熱槽型式'"
                        :value="solarSystemDetail.heat_storage_tank_type"
                        :name_colspan="3"
                    />
                    <device-table-row
                        v-if="!isPublic"
                        :name="'情報公開年月日'"
                        :value="date(solarSystemDetail.publish_date)"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'発売予定年月日'"
                        :value="date(solarSystemDetail.release_date)"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'性能確認区分'"
                        :value="solarSystemCertificates.performance_validation_category"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'成績証明書番号または自己適合宣言書番号（試験品質）'"
                        :value="solarSystemCertificates.certificate_number"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'集熱部総面積'"
                        :value="solarSystemCertificates.heat_collector_area"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'集熱部効率係数(b0)'"
                        :value="solarSystemCertificates.heat_collector_efficiency"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'集熱部損失係数(b1)'"
                        :value="solarSystemCertificates.heat_collector_loss"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'蓄熱タンク容量'"
                        :value="solarSystemCertificates.heat_storage_tank_capacity"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'有効出湯効率'"
                        :value="solarSystemCertificates.hot_water_output_efficiency"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'蓄熱槽熱損失係数'"
                        :value="solarSystemCertificates.heat_storage_tank_heat_loss"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'熱交換器伝熱係数'"
                        :value="solarSystemCertificates.heat_exchanger_heat_transfer"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'基準循環流量'"
                        :value="solarSystemCertificates.standard_circulation_flow"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'循環ポンプ連続運転時電力'"
                        :value="solarSystemCertificates.power_on_circulation_pump_continuous"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'循環ポンプ集熱判定時電力'"
                        :value="solarSystemCertificates.power_on_circulation_pump_collection"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'集熱配管の線熱通過率'"
                        :value="solarSystemCertificates.pipe_linear_heat_transfer"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'熱媒の定圧比熱'"
                        :value="solarSystemCertificates.pipe_thermal_specific_heat"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'パラメータ抽出プログラム'"
                        :value="solarSystemCertificates.sampling_program_version"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'試験基準バージョン'"
                        :value="solarSystemCertificates.standard_version"
                        :name_colspan="3"
                    />
                    <device-table-row
                        :name="'備考'"
                        :value="solarSystemDetail.note"
                        :name_colspan="3"
                    />
                </table>
                <div class="ly_buttonBox">
                    <app-button
                        name="戻る"
                        @click="back"
                        ref="jest-solar-system-detail-back-button"
                        class="cancel"
                    />
                    <app-button
                        v-if="isShowCertificate"
                        name="成績証明書又は自己適合宣言書を確認"
                        @click="getFile"
                        ref="jest-solar-system-detail-pdf-button"
                    />
                    <app-button
                        v-if="isChangeablePublishDate"
                        name="情報公開日等の変更"
                        @click="updatePublishDate"
                        ref="jest-solar-system-detail-change-publish-date"
                    />
                    <app-button
                        v-if="isEnabled"
                        name="承認"
                        @click="approveEnableDevice"
                        ref="jest-solar-system-detail-enable-button"
                    />
                    <app-button
                        name="申請"
                        @click="toPublish"
                        v-if="isAllowedToPublish"
                        ref="jest-solar-system-detail-to-publish"
                    />
                    <app-button
                        name="承認"
                        v-if="isAllowedToApprove"
                        @click="toApprove"
                        ref="jest-solar-system-detail-to-approve"
                    />
                    <div
                        v-show="showDisableButtons"
                        class="uq_foldedContainer">
                        <app-button
                            v-if="isDelete"
                            name="削除"
                            class="delete"
                            @click="deleteDevice"
                            ref="jest-solar-system-detail-delete-button"
                        />
                        <app-button
                            name="削除"
                            @click="toDelete"
                            class="delete"
                            v-if="isAllowedToDelete"
                            ref="jest-solar-system-detail-to-delete"
                        />
                        <app-button
                            name="取下"
                            @click="toCancel"
                            class="delete"
                            v-if="isAllowedToCancel"
                            ref="jest-solar-system-detail-to-cancel"
                        />
                        <app-button
                            name="取下申請"
                            v-if="isAllowedToPublish"
                            @click="toPublishAndCancel"
                            class="delete"
                            ref="jest-solar-system-detail-publish-cancel"
                        />
                    </div>
                    <app-button
                        v-if="isDelete || isAllowedToDelete || isAllowedToCancel || isAllowedToPublish"
                        name="…"
                        @click="toggle"
                        ref="jest-organization-toggle"
                        class="uq_togglebutton"
                    />
                </div>
            </div>
            <div id="invisible"></div>
        </div>
    </default-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapComputedProperties, move, isSet, isSetNumber, isSetString } from '@/util'
import moment from 'moment'
import DeviceTableRow from '@/components/molecules/DeviceTableRow.vue'
import pdf from "@/mixins/pdf"
import Progress from '@/mixins/progress.js'
/**
 * P015 ソーラーシステム機器詳細（一般）
 * P144 ソーラーシステム機器詳細（事業者）
 * P231 ソーラーシステム機器詳細（管理者）
 * 
 * [算出プロパティ]
 * packageName ... 表示している機器の"ブランド事業者名 システム型式 集熱器型式 蓄熱槽型式 成績証明書番号等"
 * date ... 文字列の年月日をYYYY/MM/DDの形に成形する
 * wasteHeatUtilization...暖房熱負荷への排熱利用の有無を文字列で返す
 * wasteHeatUtilizationType...暖房熱負荷への排熱利用方式を文字列で返す
 * reversePowerFlow...逆潮流の有無を文字列で返す
 * isPublic...publicのルートか否かを判定(表示と遷移に使用)
 * isChangeablePublishDate...公開ページでなく、情報公開日が来ていない機器であることを判定（情報公開日の変更可否の判定）
 * 
 * [イベント処理]
 * copy ... 表示している機器のブランド名、システム型式、集熱器型式、蓄熱槽型式、成績証明書番号のテキストをクリップボードにコピーする
 * back ... 表示中のページに対応した戻り先へ遷移する（一般、事業者、管理者）
 * updatePublishDate...情報公開日等の変更ページに遷移
 * getFile ... 当該機器データに紐づく成績証明書等PDFを別ウィンドウで表示する
 */
export default {
    name:'SolarSystemsDetail',
    components: {
        DeviceTableRow
    },
    data(){
        return {
            showDisableButtons:false,
        }
    },
    mixins:[pdf, Progress],
    computed:{
        ...mapGetters({
            solarSystemDetail:'solarSystems/solarSystemDetail',
            solarSystemCertificates:'solarSystems/solarSystemCertificates',
            organization:'auth/organization',
        }),
        ...mapComputedProperties([
            'publish_date',
            'release_date',
        ]),
        /**
         * 機器に対応した編集対象のデータ
         */
        data(){
            return this.solarSystemsPublishDates
        },
        /**
         * 表示している機器の"ブランド事業者名 システム型式 集熱器型式 蓄熱槽型式 成績証明書番号等"
         */
        packageName(){
            // 取得したデータを元に設定する
            //return "ブランド事業者名 システム型式 集熱器型式 蓄熱槽型式 成績証明書番号等"
            return this.brandName + ' '
                    + isSetString(this.solarSystemDetail.system_type) + ' '
                    + isSetString(this.solarSystemDetail.heat_collector_type) + ' '
                    + isSetString(this.solarSystemDetail.heat_storage_tank_type) + ' '
                    + isSetString(this.solarSystemDetail.certificate_number)
        },
        packageNameForCopy(){
            // 取得したデータを元に設定する
            //return "ブランド事業者名 システム型式 集熱器型式 蓄熱槽型式 成績証明書番号等"
            return this.brandName
                    + isSetNumber(this.solarSystemDetail.system_type)
                    + isSetNumber(this.solarSystemDetail.heat_collector_type)
                    + isSetNumber(this.solarSystemDetail.heat_storage_tank_type)
                    + isSetNumber(this.solarSystemDetail.certificate_number)
        },
        /**
         * 文字列の年月日をYYY年MM月DD日の形に成形する
         */
        date(){
            return function(dateString) {
                return moment(dateString).format('YYYY年MM月DD日')
            }
        },
        /**
         * publicのルートか否かを判定(表示と遷移に使用)
         */
        isPublic(){
            return this.$route.meta.noGuard ?? false
        },
        /**
         * 公開ページでなく、情報公開日が来ていない機器であることを判定
         * （情報公開日の変更可否の判定）
         */
        isChangeablePublishDate(){
            return this.isOrganization
        },
        /**
         * 事業者ページか否か
         */
        isOrganization() {
            return this.$route.meta.routeType === 'organization'
        },
        /**
         * 管理者ページか否か
         */
        isAdministrator() {
            return this.$route.meta.routeType === 'administrator'
        },
        /**
         * 詳細画面か否かの判定
         */
        isDetail() {
            return this.$route.meta.type === 'detail'
        },
        /**
         * 削除画面か否かの判定
         */
        isDelete() {
            return this.$route.meta.type === 'delete'
        },
        /**
         * 承認画面か否かの判定
         */
        isEnabled() {
            return this.$route.meta.type === 'approvedEnabled'
        },
        /**
         * 成績証明書の表示可否判定
         */
        isShowCertificate(){
            return (this.isDetail || this.isEnabled) && this.cannotShowCertificate === false
        },
        /**
         * ステータスによる表示不可判定
         * （一般ページかつ取下げ公開中の場合true）
         */
        cannotShowCertificate(){
            return this.isPublic && this.solarSystemDetail.status === 5
        },
        /**
         * 機器の申請・取下申請の可否を返す（事業者のみ）
         * （機器が公開申請前かつ機器を表示している事業者がブランド事業者である場合true）
         */
        isAllowedToPublish(){
            return this.isDetail && this.isOrganization && this.solarSystemDetail.status === 1 && this.isBrandUser
        },
        /**
         * 機器の削除の可否を返す
         * （事業者としての削除条件または管理者としての削除条件を満たしていればtrue）
         */
        isAllowedToDelete(){
            return this.isDetail && (this.isOrganizationAllowedToDelete ||this.isAdministratorAllowedToDelete)
        },
        /**
         * 機器の削除の可否を返す（事業者）
         * （事業者の場合、機器が公開申請前または「機器が公開申請済みかつ機器を表示している事業者がブランド事業者である」場合true）
         */
        isOrganizationAllowedToDelete(){
            return this.isOrganization && (this.solarSystemDetail.status === 1 || (this.solarSystemDetail.status === 2 && this.isBrandUser))
        },
        /**
         * 機器の削除の可否を返す（管理者）
         * （管理者の場合、公開申請済、公開承認済、一般公開中、取下公開中のいずれかのステータスの場合true）
         */
        isAdministratorAllowedToDelete(){
            const statusAbleToDelete = [2, 3, 4, 5]
            return this.isAdministrator && statusAbleToDelete.includes(this.solarSystemDetail.status)
        },
        /**
         * 機器の取下の可否を返す（事業者のみ）
         * （ステータスが公開申請済・公開承認済・一般公開中のいずれかかつ機器を表示している事業者がブランド事業者であり、
         * さらにまだ取下げフラグがfalseである場合はtrue）
         */
        isAllowedToCancel(){
            const statusAbleToCancel = [2, 3, 4]
            return this.isDetail && this.isOrganization && statusAbleToCancel.includes(this.solarSystemDetail.status)
                && this.isBrandUser && this.solarSystemDetail.canceled === false
        },
        /**
         * 機器の承認の可否を返す（管理者のみ）
         * （ステータスが公開申請済である場合true）
         */
        isAllowedToApprove(){
            return this.isDetail && this.isAdministrator && this.solarSystemDetail.status === 2
        },
        /**
         * 機器のブランド事業者IDがログイン中の事業者IDと一致するか否か
         */
        isBrandUser() {
            return this.solarSystemDetail.brand_name.organization_id == this.organization.id
        },
        brandName(){
            return isSet(this.solarSystemDetail.brand_name) ? this.solarSystemDetail.brand_name.name : null
        },
        isSoftDelete(){
            const statusForSoftDelete = [3, 4, 5]
            return this.isAdministrator && this.isDelete && statusForSoftDelete.includes(this.solarSystemDetail.status)
        }
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        // 機器詳細データを取得する
        await this.getDeviceDetail({id:this.$route.params.id, isPublic:this.isPublic})
        // データを取得できなかった場合はNot Foundを表示
        if (Object.keys(this.solarSystemDetail).length == 0) {
            move(this.$router, '/NotFound')
        } else {
            await this.getSolarSystemCertificate(this.solarSystemDetail.solar_system_certificate_id)
        }
        this.finishProgress()
        loader.hide()
    },
    methods:{
        ...mapActions({
            getDeviceDetail:'solarSystems/getDeviceDetail',
            getSolarSystemCertificate:'solarSystems/getSolarSystemCertificate',
            getDeviceCertificateFile:'solarSystems/getDeviceCertificateFile',
            deleteSolarSystems:'solarSystems/deleteDevice',
            approveEnableSolarSystems:'solarSystems/approveEnableDevice',
            approveDisableSolarSystems:'solarSystems/approveDisableDevice',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        /**
         * 表示している機器のブランド名、システム型式、集熱器型式、蓄熱槽型式、成績証明書番号のテキストをクリップボードにコピーする
         */
        copy(){
            this.$copyText(this.packageNameForCopy)
            alert("コピーしました。")
        },
        /**
         * 表示中のページに対応した戻り先へ遷移する（一般、事業者、管理者）
         */
        back(){
            if (this.isPublic) {
                move(this.$router, '/solar_systems')
            } else if (this.isOrganization) {
                move(this.$router, '/organization/solar_systems')
            } else if (this.isAdministrator) {
                move(this.$router, '/admin/solar_systems')
            }
        },
        /**
         * 情報公開日等の変更ページに遷移
         */
        updatePublishDate(){
            move(this.$router, '/organization/solar_systems/' + this.solarSystemDetail.id + '/update/publish_date')
        },
        /**
         * 当該機器データに紐づく成績証明書等PDFを別ウィンドウで表示する
         */
        async getFile(){
            const file = await this.getDeviceCertificateFile(this.solarSystemDetail.solar_system_certificate_id)
            this.openPdf(file, 'solarSystem')
        },
        /**
         * ソーラーシステム機器の削除実行
         */
        deleteDevice() {
            this.clearError()
            return this.deleteSolarSystems()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        approveEnableDevice() {
            this.clearError()
            return this.approveEnableSolarSystems()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        approveDisableDevice() {
            this.clearError()
            return this.approveDisableSolarSystems()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        },
        /**
         * 機器の削除画面に遷移
         */
        toDelete(){
            this.clearError()
            let url
            if (this.isOrganization) {
                url = '/organization/solar_systems/' + this.solarSystemDetail.id + '/delete'
            } else if (this.isAdministrator) {
                url = '/admin/solar_systems/' + this.solarSystemDetail.id + '/delete'
            }
            move(this.$router, url)
        },
        /**
         * 機器の公開申請画面に遷移（事業者のみ）
         */
        toPublish(){
            this.clearError()
            move(this.$router, '/organization/solar_systems/' + this.solarSystemDetail.id + '/status/published')
        },
        /**
         * 機器の取下画面に遷移（事業者のみ）
         */
        toCancel(){
            this.clearError()
            move(this.$router, '/organization/solar_systems/' + this.solarSystemDetail.id + '/status/canceled')
        },
        /**
         * 機器の取下申請画面に遷移（事業者のみ）
         */
        toPublishAndCancel(){
            this.clearError()
            move(this.$router, '/organization/solar_systems/' + this.solarSystemDetail.id + '/status/published/cancel')
        },
        /**
         * 機器の承認画面に遷移（管理者のみ）
         */
        toApprove(){
            this.clearError()
            move(this.$router, '/admin/solar_systems/' + this.solarSystemDetail.id + '/approved/enabled')
        },
        /**
         * 削除・取り下げのボタンを…ボタンで表示切替
         */
        toggle(){
            this.showDisableButtons = !this.showDisableButtons
        },
    }
}
</script>

<style scoped>
.bl_contents_wrapper{
    max-width: 800px;
}
.uq_width_fitter{
    width: 100%;
}
table{
    min-width: 800px;
    width: 100%;
}
.bl_underHeader >>> button.el_button.uq_btnCopy{
    width: 100px;
    min-width: 100px;
    height: 24px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.25em 1em;
    font-weight: bolder;
    border-radius: 13px;
    background-color: white;
}

.bl_underHeader >>> button.el_button.uq_btnCopy:hover{
    color: #aaa;
}

.red_bold{
    color:#F00;
    font-weight: bold;
    font-size:1.2em;
}
</style>


